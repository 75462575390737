import cx from 'classnames';
import React, { useCallback, useState } from 'react';
import { usePopper } from 'react-popper';
import { useIsCurrentLocation } from '../../../hooks/use-is-current-location';
import { MenuProps } from '../../../types/menu';
import LanguageSelect from '../../language-select/language-select';
import { AnchorLink } from '../../shared/anchor-link';
import { SecondLevelItem } from './second-level-item';
import { MenuModalContext } from '../menu';
import { ModalFormType } from '../../../types/modal';

const MenuItem = (item) => {
  const { isCurrentLocation } = useIsCurrentLocation();

  const [mouseOverButton, setMouseOverButton] = useState(false);
  const [mouseOverMenu, setMouseOverMenu] = useState(false);

  const onMouseEnterButton = () => setMouseOverButton(true);
  const onMouseLeaveButton = () => setMouseOverButton(false);

  const onMouseEnterMenu = () => setMouseOverMenu(true);
  const onMouseLeaveMenu = () => setMouseOverMenu(false);

  const show = mouseOverMenu || mouseOverButton;

  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement>();
  const [popperElement, setPopperElement] = useState<HTMLDivElement>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
  });

  const { openModal, setFormType } = React.useContext(MenuModalContext);

  const handleModalType = useCallback(() => {
    setFormType(item.modalType.contentType as ModalFormType);
    openModal();
  }, [item.modalType, setFormType, openModal]);

  const isActive = isCurrentLocation(item.url);

  return (
    <li className="relative h-full">
      <button
        ref={setReferenceElement}
        className={cx(
          'h-full',
          isActive ? 'text-green' : 'text-gray-800',
          'group rounded-md inline-flex items-center menu-links-18 hover:text-green'
        )}
        onMouseEnter={onMouseEnterButton}
        onMouseLeave={onMouseLeaveButton}
        onClick={(e) => {
          e.preventDefault();
          item.modalType && handleModalType();
        }}
      >
        {item.url ? (
          <AnchorLink to={item.url}>{item.name}</AnchorLink>
        ) : (
          item.name
        )}
      </button>

      <div
        ref={setPopperElement}
        style={{ ...styles.popper, top: '1rem' }}
        {...attributes.popper}
        onMouseEnter={onMouseEnterMenu}
        onMouseLeave={onMouseLeaveMenu}
        className="z-1"
      >
        <div
          className={cx(
            'rounded-lg ring-opacity-5 overflow-hidden transition-all flex relative bg-white',
            {
              'max-h-0 opacity-0 scale-95 ease-in duration-100': !show,
              'max-h-full ease-out opacity-100 scale-100 duration-200': show,
              'shadow-lg ring-1 ring-black ': show,
            }
          )}
        >
          {item.additionalLevel?.length > 0 && (
            <div
              className={cx(
                'flex content-start items-start py-4',

                item.isVertical
                  ? 'flex-col'
                  : {
                      'flex-wrap w-[1000px]': item.additionalLevel?.length >= 3,
                      'flex-wrap 2xl:max-w-[1300px] max-w-[1000px] 2xl:w-[1300px]':
                        item.additionalLevel?.length >= 4,
                    }
              )}
            >
              {item.additionalLevel.map((additionalItem, i) => {
                const content = (
                  <div
                    key={i}
                    className={cx(
                      'min-h-[22px] w-full menu-links-16 text-gray-900 pl-8 flex items-center py-3',
                      {
                        'hover:text-green hover:bg-gray-100':
                          additionalItem.url,
                      }
                    )}
                  >
                    {additionalItem.name}
                  </div>
                );

                return (
                  <div
                    key={additionalItem.name}
                    className={cx(
                      item.additionalLevel?.length >= 3 && !item.isVertical
                        ? 'flex-1'
                        : 'w-[325px]',
                      !item.isVertical &&
                        'h-full border-solid border-r border-slate-100 last:border-0'
                    )}
                  >
                    {additionalItem.url ? (
                      <AnchorLink to={additionalItem.url}>{content}</AnchorLink>
                    ) : (
                      content
                    )}
                    <ul>
                      {additionalItem.secondLevel?.map((secondLevelItem) => (
                        <SecondLevelItem
                          key={secondLevelItem.name}
                          className="font-normal"
                          contentClassName="pl-12"
                          {...secondLevelItem}
                        />
                      ))}
                    </ul>
                  </div>
                );
              })}
            </div>
          )}
          {item.secondLevel?.length > 0 && (
            <ul
              className={cx(
                'grid w-[325px] content-start py-4',
                item.additionalLevel.length > 0
                  ? 'border-solid border-l border-slate-100'
                  : ''
              )}
            >
              {item.secondLevel.map((secondLevelItem) => (
                <SecondLevelItem
                  isAdditionalVisible={item.additionalLevel.length > 0}
                  key={secondLevelItem.name}
                  {...secondLevelItem}
                />
              ))}
            </ul>
          )}
        </div>
      </div>
    </li>
  );
};

export const MenuDesktop: React.FC<MenuProps> = ({ firstLevel }) => (
  <nav className="hidden lg:flex">
    <ul className="flex gap-x-4 lg-menu:gap-x-8 items-center">
      {firstLevel.map((item) => (
        <MenuItem key={item.name} {...item} />
      ))}
    </ul>
    <div className="ml-6">
      <LanguageSelect />
    </div>
  </nav>
);
