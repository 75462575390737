import { default as React, useMemo, useState } from 'react';
import * as yup from 'yup';
import { useTranslationsContextData } from '../../../../hooks/use-context-data';
import { ModalFormType } from '../../../../types/modal';
import { Button } from '../../../button/button';
import { Input } from '../../../input/input';
import { RadioGroup } from '../../../radio-group/radio-group';
import { Select } from '../../../select/select';
import { LoadingSpinner } from '../../../shared/loading-spinner';
import { ModalContext } from '../../modal';
import { TherapyForm } from './therapy-form';
import { useIntl } from 'gatsby-plugin-react-intl';

// const MAX_ATTACHMENTS_SIZE = 1024 * 1024 * 20; //20MB
export interface ModalFormProps {
  type: ModalFormType;
  externalCloseModal?: () => void;
}

export const ModalForm: React.FC<ModalFormProps> = ({
  type,
  externalCloseModal,
}) => {
  const { closeModal } = React.useContext(ModalContext);
  const translations = useTranslationsContextData();
  const [isLoading, setIsLoading] = useState(false);
  const { locale } = useIntl();
  const contactHourOptions = useMemo(
    () => [
      // {
      //   id: 0,
      //   value: translations['form.contact-hours.slot-0'],
      // },
      {
        id: 1,
        value: translations['form.contact-hours.slot-1'],
      },
      {
        id: 2,
        value: translations['form.contact-hours.slot-2'],
      },
      {
        id: 3,
        value: translations['form.contact-hours.slot-3'],
      },
      {
        id: 4,
        value: translations['form.contact-hours.slot-4'],
      },
      {
        id: 5,
        value: translations['form.contact-hours.slot-5'],
      },
      {
        id: 6,
        value: translations['form.contact-hours.slot-6'],
      },
      {
        id: 7,
        value: translations['form.contact-hours.slot-7'],
      },
      {
        id: 8,
        value: translations['form.contact-hours.slot-8'],
      },
      {
        id: 9,
        value: translations['form.contact-hours.slot-9'],
      },
      // {
      //   id: 10,
      //   value: translations['form.contact-hours.slot-10'],
      // },
    ],
    [translations]
  );

  const requiredError = translations['form.required-field-error'];

  const onCancel = externalCloseModal || closeModal;

  const defaultValues = useMemo(
    () => ({
      name: '',
      // age: '',
      email: '',
      phoneNumber: '',
      contact: '',
      contactHour: contactHourOptions[0],
      additional: '',
      // attachments: [],
    }),
    [
      contactHourOptions
    ]
  );

  const defaultSchema = yup.object().shape({
    name: yup.string().required(requiredError).min(2, requiredError),
    // age: yup.string().required(requiredError),
    email: yup
      .string()
      .required(requiredError)
      .email(translations['form.email-format-error']),
    phoneNumber: locale === "en" ? yup.string().optional() :  yup.string().required(requiredError),
    contact: yup.string(),
    // .required(requiredError),
    contactHour: yup
      .object()
      .nullable()
      .when('contact', {
        is: 'phone',
        then: yup.object().required(requiredError).typeError(requiredError),
      }),

    additional: yup.string().optional(),
    // attachments: yup
    //   .array()
    //   .test(
    //     'fileSize',
    //     translations['form.upload-file-size-error'],
    //     (attachments) => {
    //       if (!attachments.length) return true;
    //       return (
    //         attachments.reduce((sum, file) => sum + parseInt(file.size), 0) <
    //         MAX_ATTACHMENTS_SIZE
    //       );
    //     }
    //   ),
  });

  const FormComponent = type === 'therapy' ? TherapyForm : null;

  if (!FormComponent) {
    return null;
  }

  return (
    <FormComponent
      type={type}
      setIsLoading={setIsLoading}
      commonFieldsDefaultValues={defaultValues}
      commonFieldsSchema={defaultSchema}
      externalCloseModal={externalCloseModal}
      commonFormFields={
        <>
          <Input
            name="name"
            label={translations['form.name-label']}
            placeholder={translations['form.name-placeholder']}
          />
          {/* <Input
            name="age"
            label={translations['form.age-label']}
            placeholder={translations['form.age-placeholder']}
          /> */}
          <Input
            name="email"
            label={translations['form.email-label']}
            placeholder={translations['form.email-placeholder']}
          />

          {locale === 'en' ? null : (
            <>
              <Input
                name="phoneNumber"
                label={translations['form.number-label']}
                placeholder={translations['form.phone-placeholder']}
              />
              <RadioGroup
                name="contact"
                label={translations['form.contact-label']}
                options={[
                  {
                    className: 'mb-4',
                    value: 'email',
                    id: 'email',
                    label: translations['form.email-contact-label'],
                  },
                  {
                    value: 'phone',
                    id: 'phone',
                    label: translations['form.phone-label'],
                    extraContent: (
                      <div>
                        <p className="form-label lg:body-xs text-gray-500 mb-2">
                          {translations['form.phone-contact-description']}
                        </p>

                        <Select
                          name="contactHour"
                          options={contactHourOptions}
                        />
                      </div>
                    ),
                  },
                ]}
              />
            </>
          )}
        </>
      }
    >
      <div className="sticky bg-gradient-to-b from-white via-gray-50 to-gray-300 bottom-0 z-10 px-6 py-4 md:py-9">
        <div className="max-w-8xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-x-9 gap-y-4 text-left">
          <Button
            full
            type="outline-light"
            onClick={onCancel}
            size="sm"
            htmlType="button"
          >
            {translations['button.cancel']}
          </Button>
          <Button full size="sm" htmlType="submit" disabled={isLoading}>
            {isLoading ? (
              <div className="flex justify-center py-1">
                <LoadingSpinner />
              </div>
            ) : (
              translations['button.send-application']
            )}
          </Button>
        </div>
      </div>
    </FormComponent>
  );
};
