import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { CTASection } from '../components/cta-section/cta-section';
import { CTA } from '../components/cta/cta';
import FAQ from '../components/faq/faq';
import Layout from '../components/layout/layout';
import { ModalDialog } from '../components/modal/modal';
import { ModalPayment } from '../components/modal/modal-types/modal-payment';
import {
  PricingSection,
  PricingSectionProps,
} from '../components/pricing/pricing-section/pricing-section';
import { PaymentProps } from '../types/payment';
import { getUrlLocalizations } from '../utils/parse-localization-url';
import { useIntl } from 'gatsby-plugin-react-intl';

export const Services = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [paymentProps, setPaymentProps] = useState<PaymentProps>();
  const { locale } = useIntl();

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const price = urlSearchParams.get('price');
    const title = urlSearchParams.get('title');
    if (price && title) {
      setIsOpen(true);
      setPaymentProps({
        price: {
          value: price,
          currency: locale === "en" ? "EUR" : 'PLN',
        },
        title: title,
      });
    }
  }, []);

  if (!data.services) return null;

  const { tabsHeading, pricingSections, faq, cta, seo } = data.services;

  return (
    <Layout
      {...data}
      seo={seo}
      localizations={getUrlLocalizations(
        'services',
        data.urls.localizations,
        data.urls
      )}
    >
      {tabsHeading && (
        <CTASection headingProps={{ tag: 'h1' }} {...tabsHeading} />
      )}
      {pricingSections &&
        pricingSections.map(
          ({
            sectionId,
            ...section
          }: PricingSectionProps & { sectionId: string }) => (
            <PricingSection key={sectionId} id={sectionId} {...section} />
          )
        )}
      {faq && <FAQ className="bg-white" {...faq} />}
      {cta && (
        <CTA
          className="bg-gray-100"
          {...cta}
          button={{
            ...cta.button,
            positonRight: true,
          }}
        />
      )}
      <ModalDialog isOpen={isOpen}>
        <ModalPayment
          externalCloseModal={closeModal}
          paymentProps={paymentProps}
        />
      </ModalDialog>
    </Layout>
  );
};

export default Services;

export const query = graphql`
  query ServicesPage($language: String) {
    modals: allStrapiModalType(filter: { locale: { eq: $language } }) {
      nodes {
        ...ModalTypeFragment
      }
    }
    translations: strapiTranslation(locale: { eq: $language }) {
      ...TranslationsFragment
    }
    settings: strapiSetting(locale: { eq: $language }) {
      ...SettingsFragment
    }
    menu: strapiMenu(locale: { eq: $language }) {
      ...MenuFragment
    }
    footer: strapiFooter(locale: { eq: $language }) {
      ...FooterFragment
    }
    socialLinks: strapiSocialLink(locale: { eq: $language }) {
      ...SocialLinksFragment
    }
    contactData: strapiContactData(locale: { eq: $language }) {
      ...ContactDataFragment
    }
    announcementBar: strapiAnnouncementBar(locale: { eq: $language }) {
      ...AnnouncementBarFragment
    }
    urls: strapiUrl(locale: { eq: $language }) {
      ...UrlsFragment
    }
    services: strapiServicesPage(locale: { eq: $language }) {
      seo {
        ...SeoFragment
      }
      tabsHeading {
        heading {
          title
          description {
            data {
              childMdx {
                rawBody
              }
            }
          }
        }
        tabs {
          ...ButtonFragment
        }
      }
      pricingSections {
        sectionId
        isSubSection
        heading {
          ...HeadingAllFragment
        }
        pricings {
          pricingId
          title
          description: childStrapiComponentServicesPricingDescriptionTextnode {
            childMdx {
              body
            }
          }
          type
          price {
            ...PriceFragment
          }
          pricingTeam {
            price {
              ...PriceFragment
            }
            summaryList {
              text {
                data {
                  childMdx {
                    body
                  }
                }
              }
            }
            teamMember {
              id
              name
              role
              appointment {
                url
              }
              photo {
                ...ImageFragment
              }
              categories {
                ...CategoryFragment
              }
            }
          }

          summaryTitle
          summaryList {
            text {
              data {
                childMdx {
                  body
                }
              }
            }
          }
          buttonPrimary {
            ...PricingButtonFragment
          }
          buttonSecondary {
            ...PricingButtonFragment
          }
          additionalModal {
            ...PricingButtonFragment
          }
        }
        cta {
          heading {
            title
            description {
              data {
                childMdx {
                  rawBody
                }
              }
            }
          }
          button {
            ...ButtonFragment
          }
        }
      }
      faq {
        ...FAQFragment
      }
      cta {
        ...CTAFragment
      }
    }
  }
`;
