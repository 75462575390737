import { Dialog } from '@headlessui/react';
import XIcon from '@heroicons/react/outline/esm/XIcon';
import cx from 'classnames';
import React from 'react';
import { getIcon } from '../../utils/getIcon';
import { HeadingProps } from '../heading/heading';

interface ModalContentProps {
  heading?: HeadingProps;
  icon?: string;
  headerContent?: JSX.Element;
  size?: 'md' | 'lg' | 'gallery';
  onClose?: () => void;
  className?: string;
}

export const ModalContent: React.FC<ModalContentProps> = ({
  children,
  heading,
  icon,
  headerContent,
  size = 'md',
  onClose,
  className,
}) => {
  const Icon = getIcon(icon);

  return (
    <div className="flex justify-center items-center min-h-screen overflow-hidden align-middle transition-all transform">
      <div
        className={cx('px-6 md:p-9 bg-white shadow-xl  relative', {
          'py-9': size !== 'gallery',
          'max-w-2xl lg:w-2xl rounded-2xl': size === 'md',
          'pb-6 max-w-8xl lg:w-6xl rounded-2xl': size === 'gallery',
          'xl:w-screen': size === 'lg',
        })}
      >
        {size !== 'md' && (
          <div className="z-1 absolute top-[2.25rem] right-[2.25rem]">
            <button onClick={onClose} type="button">
              <XIcon className="h-4 w-4 lg:h-7 lg:w-7" aria-hidden="true" />
            </button>
          </div>
        )}
        <div className={cx(className, 'flex flex-col')}>
          {Icon && (
            <div className="self-center bg-green h-12 w-12 rounded-xl shadow-lg flex items-center justify-center">
              <Icon className="w-6 h-6 text-white" />
            </div>
          )}
          {headerContent}

          <Dialog.Title
            as="h3"
            className="pt-9 h500 uppercase lg:normal-case lg:h400 text-gray-900"
          >
            {heading?.title}{' '}
            {heading?.highlight && (
              <span className="text-green">{heading?.highlight}</span>
            )}
          </Dialog.Title>

          {heading?.description && (
            <Dialog.Description className="pt-4 body-xs lg:body-s text-gray-500">
              {heading?.description}
            </Dialog.Description>
          )}

          <div className="pt-9">{children}</div>
        </div>
      </div>
    </div>
  );
};
